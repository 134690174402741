/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.css";
import { useLoadingContext } from "../../context/loading";
import { MESSAGE_CONTACT, RESPONSE_STATUS, ROUTE } from "../../config";
import classNames from "classnames";
import BreadCrumb, { BreadCrumbItem } from "../../components/breadcrumb";
import useGo from "../../helper/use-go";
import dayjs, { Dayjs } from "dayjs";
import CoverPage from "../../components/cover-page";
// import SelectBox from "../../components/select";
import { ContactForm, ContactFormValid, mockContactForm, mockContactFormValid } from "../../interface/model/contact";
import { isMobile } from "react-device-detect";
import { validateEmail, validateRequired } from "../../helper";
import { cloneDeep } from "lodash";
import { ContactService } from "../../api/contact";
import { useLocation } from "react-router-dom";
import { CheckCircleOutlineOutlined, WarningAmberOutlined, ErrorOutlineRounded, LocationOn, AccessTimeFilled, LocalPhone, Email, Facebook, Instagram  } from '@mui/icons-material';
import Modal from "../../components/modal";
import ModalPrivacyPolicy from "../../components/modal-privacy-policy";

export default function ContactUs() {
  const { setLoading } = useLoadingContext();
  const location = useLocation();
  const go = useGo();

  const [form, setForm] = useState<ContactForm>(cloneDeep(mockContactForm));
  const [formValid, setFormValid] = useState<ContactFormValid>(cloneDeep(mockContactFormValid));
  const [modalContactData, setModalContactData] = useState<any>({});
  const [openModalConfirmContact, setOpenModalConfirmContact] = useState<boolean>(false);
  const [openModalPrivacyPolicy, setOpenModalPrivacyPolicy] = useState<boolean>(false);

  // const [travelDate, setTravelDate] = useState<any[]>([]);
  // const [selectedTravelDate, setSelectedTravelDate] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setLoading(false);
    handleSetTravelDate();
    resetForm();
  }, []);

  useEffect(() => {
    resetForm();
  }, [location]);

  useEffect(() => {
    if (isSubmit) {
      validForm();
    }
  }, [form]);

  const handleSetTravelDate = () => {
    let months: any[] = [];
    const current: Dayjs = dayjs();

    for (let i = 0; i < 12; i++) {
      months.push({
        label: dayjs(current.add(i, "months")).format("MMM YYYY"),
        value: dayjs(current.add(i, "months")).format("YYYY-MM") + "-01",
      });
    }

    // setTravelDate(months);
  };

  // Change value
  // const onChangeTravelDate = (option: any) => {
  //   setForm((prevForm) => ({
  //     ...prevForm,
  //     travel_date: dayjs(option.value).toJSON(),
  //   }));
  //   setSelectedTravelDate(option);
  // };

  const onToggleAccept = (checked: boolean) => {
    setForm((prevForm) => ({
      ...prevForm,
      accept_policy: checked,
    }));
  };

  const onChangeForm = (value: string, name: string) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Submit
  const submitForm = async () => {
    setIsSubmit(true);

    if (!validForm()) {
      return;
    }

    setIsLoading(true);

    // Call api
    const getAQuote = async () => {
      const service = ContactService();
      const res = await service.contact(form);
      if (res && res.status === RESPONSE_STATUS.SUCCESS) {
        setMessage(MESSAGE_CONTACT.CONTACT_SUCCESS);
        setIsError(false);
        setIsLoading(false);
        resetForm();

        setModalContactData({
          status: RESPONSE_STATUS.SUCCESS,
          message: res.message
        });
        setOpenModalConfirmContact(true);
      } else {
        setMessage(MESSAGE_CONTACT.CONTACT_ERROR);
        setIsError(true);
        setIsLoading(false);

        setModalContactData({
          status: RESPONSE_STATUS.ERROR,
          message: res.message
        });
        setOpenModalConfirmContact(true);
      }
    };
    getAQuote();
  };

  const resetForm = () => {
    mockContactForm.travel_date = "";
    setForm(cloneDeep(mockContactForm));
    setFormValid(cloneDeep(mockContactFormValid));
    // setSelectedTravelDate(null);

    setMessage("");
    setIsError(false);
    setIsSubmit(false);
  }

  const validForm = (): boolean => {
    let isValid: boolean = true;

    // Travel date
    // isValid = validateRequired(form.travel_date, (valid: string) => {
    //   formValid.travel_date_valid = valid ? "Travel month is required." : "";
    // })
    //   ? isValid
    //   : false;

    // First name
    isValid = validateRequired(form.firstname, (valid: string) => {
      formValid.firstname_valid = valid ? "First name is requried." : "";
    })
      ? isValid
      : false;

    // Last name
    isValid = validateRequired(form.lastname, (valid: string) => {
      formValid.lastname_valid = valid ? "Last name is requried." : "";
    })
      ? isValid
      : false;

    // Phone number
    isValid = validateRequired(form.phone_number, (valid: string) => {
      formValid.phone_number_valid = valid ? "Phone number is requried." : "";
    })
      ? isValid
      : false;

    // Email
    isValid = validateEmail(form.email, (valid: string) => {
      formValid.email_valid =
        valid && valid?.includes("format")
          ? valid
          : valid
            ? "Email is required."
            : "";
    })
      ? isValid
      : false;

    // Accept
    if (!form.accept_policy) {
      formValid.accept_policy_valid = "Please accept our vacations privacy policy.";
      isValid = false;
    } else {
      formValid.accept_policy_valid = "";
    }

    setFormValid(cloneDeep(formValid));
    return isValid;
  };

  return (
    <>
      <div
        className={classNames(
          "destinations-container",
          // "bg-gradient-to-b from-blue-500 to-cyan-500 h-screen text-white text-3xl"
        )}
      >
        <BreadCrumb
          className={classNames(
            "bg-white text-gray-800 mt-[70px] z-10",
            "lg:mt-[74.5px] w-full absolute",
          )}
        >
          <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
          <BreadCrumbItem title="Contact Us" isActive />
        </BreadCrumb>

        {/* Header */}
        <div className="header-container">
          <CoverPage
            title={"Contact Us"}
            heightDetail={"lg:h-1/4"}
            isHideSearch
            className="pt-12"
          />
        </div>
      </div>

      {/* Content */}
      <div className="bg-white z-20">
        <div className="center w-5/6 left-0 right-0 relative m-auto lg:px-32 pt-20 text-gray-600 bg-white">
          {/* Title */}
          <h3 className="font-semibold text-xl lg:text-2xl">
            {/* Northern Smile Travel: Your Premier Partner in Tailored Journeys */}
            We’d love to hear from you!
          </h3>

          {/* Infomation */}
          <div className="information mt-5 font-semibold">
            {/* <p>Contact information</p>
            <p>Contact: info@northernsmiletravel.com</p> */}
            If you have any questions, need assistance, or want to plan your next adventure in Thailand and Indochina, please reach out to us. Our team will get back to you as soon as possible.
          </div>

          {/* Form */}
          <div className="form mt-10 text-left">
            <div className="lg:grid lg:grid-cols-2 lg:gap-4 mb-5">
              {/* Travel date */}
              {/* <label htmlFor="travel-dates" className="col-span-2">
                Select your preferred travel dates *
              </label>

              <SelectBox
                className="cursor-pointer col-span-2 my-3 lg:my-0"
                placeholder="Select your preferred travel month *"
                selectedTextAlign="left"
                value={selectedTravelDate}
                options={travelDate}
                onChange={onChangeTravelDate}
                error={formValid.travel_date_valid}
              /> */}

              {/* Information */}
              <div className="firstname">
                <input
                  type="text"
                  className={classNames(
                    "input input-bordered w-full mb-3 lg:mb-0",
                    formValid?.firstname_valid &&
                      "border-red-500 rounded-md border-[1px]"
                  )}
                  placeholder="First Name *"
                  style={{
                    fontSize: isMobile ? '0.8rem' : '1rem',
                  }}
                  value={form.firstname}
                  onChange={(event: any) => onChangeForm(event.target.value, 'firstname')}
                />
                {formValid?.firstname_valid && (
                  <div className="text-red-600 text-sm w-full cursor-default mt-1">
                    {formValid?.firstname_valid}
                  </div>
                )}
              </div>

              {/* Lastname */}
              <div className="lastname">
                <input
                  type="text"
                  className={classNames(
                    "input input-bordered w-full mb-3 lg:mb-0",
                    formValid?.lastname_valid &&
                      "border-red-500 rounded-md border-[1px]"
                  )}
                  placeholder="Last Name *"
                  style={{
                    fontSize: isMobile ? '0.8rem' : '1rem',
                  }}
                  value={form.lastname}
                  onChange={(event: any) => onChangeForm(event.target.value, 'lastname')}
                />
                {formValid?.lastname_valid && (
                  <div className="text-red-600 text-sm w-full cursor-default mt-1">
                    {formValid?.lastname_valid}
                  </div>
                )}
              </div>

              {/* Phone number */}
              <div className="phone-number">
                <input
                  type="text"
                  className={classNames(
                    "input input-bordered w-full mb-3 lg:mb-0",
                    formValid?.phone_number_valid &&
                      "border-red-500 rounded-md border-[1px]"
                  )}
                  placeholder="Phone Number *"
                  style={{
                    fontSize: isMobile ? '0.8rem' : '1rem',
                  }}
                  value={form.phone_number}
                  onChange={(event: any) => onChangeForm(event.target.value, 'phone_number')}
                />
                {formValid?.phone_number_valid && (
                <div className="text-red-600 text-sm w-full cursor-default mt-1">
                  {formValid?.phone_number_valid}
                </div>
              )}
              </div>

              {/* Email */}
              <div className="email">
                <input
                  type="text"
                  className={classNames(
                    "input input-bordered w-full mb-3 lg:mb-0",
                    formValid?.email_valid &&
                      "border-red-500 rounded-md border-[1px]"
                  )}
                  placeholder="E-Mail *"
                  style={{
                    fontSize: isMobile ? '0.8rem' : '1rem',
                  }}
                  value={form.email}
                  onChange={(event: any) => onChangeForm(event.target.value, 'email')}
                />
                {formValid?.email_valid && (
                  <div className="text-red-600 text-sm w-full cursor-default mt-1">
                    {formValid?.email_valid}
                  </div>
                )}
              </div>

              {/* Noti confirm */}
              <div className="inline-block col-span-2 mx-auto mt-2">
                <input
                  type="checkbox"
                  className="checkbox checkbox-sm w-full"
                  checked={form.accept_policy}
                  onChange={(event: any) => onToggleAccept(event.target.checked)}
                />
                <label
                  htmlFor="noti-confirm"
                  className={classNames(
                    "col-span-2 pl-3 text-[0.9rem] lg:text-[1rem]",
                    formValid?.accept_policy_valid && "text-red-600"
                  )}
                >
                  <span onClick={() => onToggleAccept(!form.accept_policy)}>* Please confirm you have read and accept the </span>
                  <span className="text-red-600">Northern Smile Travel </span>
                  <span className="text-blue-800 cursor-pointer" onClick={() => setOpenModalPrivacyPolicy(true)}><u>Privacy Policy</u></span>.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#3E545B] z-20 pb-10">
        <div className="center w-11/12 lg:w-5/6 left-0 right-0 relative m-auto px-5 lg:px-32 pb-20 text-gray-700 bg-white rounded-b-md">
          <div className="form text-left">
            {/* Additional Information */}
            <div className="grid grid-cols-2 gap-4 mb-5">
              <label
                htmlFor="additional-information"
                className="text-lg font-semibold col-span-2 mt-5 lg:mt-2"
              >
                Additional Information
              </label>
              <textarea
                name="additional-information"
                id="additional-information"
                className="textarea textarea-bordered col-span-2 h-40"
                value={form.message}
                onChange={(event: any) => onChangeForm(event.target.value, 'message')}
              ></textarea>
            </div>

            {/* Message */}
            {message && (
              <div className={classNames(
                "message my-4 p-5 col-span-2",
                !isError && "bg-green-50 border-green-600 border-[1px] rounded-md text-sm text-green-600",
                isError && "bg-red-50 border-red-600 border-[1px] rounded-md text-sm text-red-600",
              )}>
                {message}
              </div>
            )}

            {/* Submit */}
            <div className="submit text-right">
              <button
                type="button"
                className={classNames(
                  "btn rounded-3xl min-w-40",
                  "nst-btn"
                )}
                onClick={submitForm}
                disabled={isLoading}
              >
                SUBMIT
              </button>
            </div>

            {/* Address */}
            <div className="address-wrapper mt-10">
              <label
                htmlFor="additional-information"
                className="text-lg font-semibold col-span-2 mt-5 lg:mt-2"
              >
                Northern Smile Travel
              </label>
              <div className="address mt-3 px-4 flex items-start space-x-2">
                <LocationOn />
                <span className="pl-3 text-[0.9rem]">
                  14, Soi 5, Ratchadamnoen Road, Muang, Chiang Mai 50200 Thailand
                </span>
              </div>
              <div className="open-close mt-2 px-4 flex items-start space-x-2">
                <AccessTimeFilled />
                <span className="pl-3 text-[0.9rem]">
                  Working Hours<br/>
                  Open : Monday-Saturday 10.00 a.m. - 7.00 p.m.<br/>
                  Closed : Sunday and Public Holidays
                </span>
              </div>
              <div className="phone mt-2 px-4 flex items-start space-x-2">
                <LocalPhone />
                <span className="pl-3 text-[0.9rem]">
                  <a href="tel:+6653289657">
                    +66 53 289 657
                  </a>
                  ,
                  <a href="tel:+66882582711">
                    +66 88 258 2711
                  </a>
                </span>
              </div>
              <div className="email mt-2 px-4 flex items-start space-x-2">
                <Email />
                <span className="pl-3 text-[0.9rem]">
                  <a href="mailto:reservations@northernsmiletravel.com" target="_blank" rel="noreferrer">
                    reservations@northernsmiletravel.com
                  </a>
                </span>
              </div>
              <div className="facebook mt-2 px-4 flex items-start space-x-2">
                <Facebook />
                <span className="pl-3 text-[0.9rem]">
                  <a href="https://www.facebook.com/northernsmiletravel" target="_blank" rel="noreferrer">
                    Northern Smile Travel
                  </a>
                </span>
              </div>
              <div className="line mt-2 px-4 flex items-start space-x-2">
                <img src="/asset/logo/social/line-icon.svg" alt="line" className="w-[24px]" />
                <span className="pl-3 text-[0.9rem]">
                  <a href="https://line.me/ti/p/~northernsmile" target="_blank" rel="noreferrer">
                    northernsmile
                  </a>
                </span>
              </div>
              <div className="instagram mt-2 px-4 flex items-start space-x-2">
                <Instagram />
                <span className="pl-3 text-[0.9rem]">
                  <a href="https://www.instagram.com/Northernsmiletravel" target="_blank" rel="noreferrer">
                    northernsmile
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Privacy policy modal */}
      <ModalPrivacyPolicy
        isOpen={openModalPrivacyPolicy}
        setIsOpen={(value: boolean) => setOpenModalPrivacyPolicy(value)}
      />

      {/* Modal confirm subscribe */}
      <Modal
        title=""
        titlePosition="center"
        titleBorder={false}
        isOpen={openModalConfirmContact}
        setIsOpen={(value: boolean) => setOpenModalConfirmContact(value)}
        btnClose="Close"
        onClose={() => setOpenModalConfirmContact(false)}
      >
        <div className="icon my-5">
          {
            modalContactData?.status === "success" && (
              <CheckCircleOutlineOutlined style={{ fontSize: '60px' }} className="text-[#018181]" />
            )
          }
          {
            modalContactData?.status === "warning" && (
              <WarningAmberOutlined style={{ fontSize: '60px' }} className="text-yellow-400" />
            )
          }
          {
            modalContactData?.status === "error" && (
              <ErrorOutlineRounded style={{ fontSize: '60px' }} className="text-red-400" />
            )
          }
        </div>
        <div className="text-xl font-semibold mb-10">
          <span dangerouslySetInnerHTML={{__html: modalContactData?.message?.replaceAll('\n','<br/>')}}></span>
        </div>
      </Modal>
    </>
  );
}
