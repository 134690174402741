/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLoadingContext } from "../../context/loading";
import { RESPONSE_STATUS, ROUTE } from "../../config";
import "./style.css";
import BreadCrumb, { BreadCrumbItem } from "../../components/breadcrumb";
import classNames from "classnames";
import useGo from "../../helper/use-go";
import BannerFull from "../../components/banner-full";
import { TourService } from "../../api/tour";
import CoverPage from "../../components/cover-page";

export default function Destinations() {
  const { setLoading } = useLoadingContext();
  const go = useGo();

  const [tours, setTours] = useState<any[]>([]);

  useEffect(() => {
    setLoading(false);
    handleSetTours();
  }, []);

  const handleSetTours = () => {
    const getTours = async () => {
      setLoading(true);

      const service = TourService();
      const res = await service.getToursCountries({});
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        res.data.map((item: any) => {
          item.link = `${ROUTE.DESTINATION}/${item.id}`;
          return item;
        });

        setTours(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getTours();
  };

  return (
    <>
      <div
        className={classNames(
          "destinations-container",
        )}
      >
        <BreadCrumb
          className={classNames(
            "bg-white text-gray-800 mt-[70px] z-10",
            "lg:mt-[74.5px] w-full absolute",
          )}
        >
          <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
          <BreadCrumbItem title="Destinations" isActive />
        </BreadCrumb>

        {/* Header */}
        <div className="header-container">
          <CoverPage
            title={"Destinations"}
            heightDetail={"lg:h-1/4"}
            isHideSearch
            // className="bg-gradient-to-r from-cyan-500 to-blue-500"
          />
        </div>
      </div>

      {/* Content */}
      <div className="bg-white">
        <div className="center shadow-xl lg:w-5/6 bg-white m-auto left-0 right-0 pb-8 relative bottom-32">
          {tours &&
            tours?.map((item: any, index: number) => {
              return (
                <div className="pt-8 px-4 lg:px-8" key={`desctinations-${index}`}>
                  <BannerFull
                    title={item.title}
                    description={item.description}
                    image={item.image}
                    link={item.link}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
