/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import "./style.css";
import BreadCrumb, { BreadCrumbItem } from "../../../components/breadcrumb";
import { useLoadingContext } from "../../../context/loading";
import useGo from "../../../helper/use-go";
import { useEffect, useState } from "react";
import { RESPONSE_STATUS, ROUTE } from "../../../config";
import { serviceService } from "../../../api/service";
import { useParams } from "react-router-dom";
import { scrollToTop } from "../../../helper/scroll";
import CoverPage from "../../../components/cover-page";

export default function ServiceDetail() {
  const { setLoading } = useLoadingContext();
  const go = useGo();
  const { id } = useParams();

  const [detail, setDetail] = useState<any | undefined>(undefined);

  useEffect(() => {
    handleSetServiceDetail();
  }, [id]);

  const handleSetServiceDetail = async () => {
    const getById = async () => {
      setLoading(true);

      const service = serviceService();
      const res = await service.getServiceById(id);
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        setDetail(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getById();
  };

  return (
    <>
      <div
        className={classNames(
          "services-container",
          "h-screen text-3xl",
        )}
      >
        <BreadCrumb
          className={classNames(
            "bg-white text-gray-800 mt-[70px] z-10",
            "lg:mt-[74.5px] w-full absolute",
          )}
        >
          <BreadCrumbItem title="Home" onClick={() => go(ROUTE.HOME)} />
          <BreadCrumbItem title="Services" onClick={() => go(ROUTE.SERVICE)} />
          <BreadCrumbItem title={detail?.title} isActive />
        </BreadCrumb>

        {/* Header */}
        <div className="header-container">
          <CoverPage
            title={detail?.title}
            detailPosition="left"
            isHideSearch
            className="pt-12"
          />
        </div>
      </div>

      {/* Content */}
      <div className="bg-white">
        <div className={classNames(
          "center w-11/12 bg-white m-auto left-0 right-0 pt-20 pb-8 px-3 relative bottom-10 text-left",
          "lg:pl-16 lg:bottom-40"
        )}>
          {/* Title */}
          <h1 className={classNames(
            "font-semibold text-4xl text-gray-800 mb-2",
            "lg:6xl"
          )}>
            {detail?.title}
          </h1>

          {/* Description */}
          <div className="detail my-10 text-lg">
            <p>{detail?.description}</p>
          </div>

          {/* Detail */}
          {detail?.details?.map((item: any, index: number) => {
            return (
              <div key={`service-detail-${index}`}>
                {/* Desktop */}
                <div className="display-desktop hidden lg:block">
                  <div className="mb-10" key={`${detail?.title}-detail-${index}`}>
                    {index % 2 === 0 ? (
                      <div
                        className="grid grid-cols-2 gap-4"
                      >
                        {/* image */}
                        <img
                          src={item?.image}
                          alt={`service-${detail?.title}-${index}`}
                        />

                        {/* detail */}
                        <div className="pl-5">
                          <h3 className="text-3xl text-gray-600 pt-10 pb-8 font-semibold">{item?.title}</h3>
                          <p className="text-gray-800 pr-5">{item?.description}</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="grid grid-cols-2 gap-4"
                      >
                        {/* detail */}
                        <div className="pl-5">
                          <h3 className="text-3xl text-gray-600 pt-10 pb-8 font-semibold">{item?.title}</h3>
                          <p className="text-gray-800 pr-5">{item?.description}</p>
                        </div>

                        {/* image */}
                        <img
                          src={item?.image}
                          alt={`service-${detail?.title}-${index}`}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Mobile */}
                <div className="display-mobile lg:hidden mb-5">
                  {/* <div
                    className="grid grid-cols-2 gap-4"
                  > */}
                    {/* image */}
                    <img
                      src={item?.image}
                      alt={`service-${detail?.title}-${index}`}
                    />

                    {/* detail */}
                    <div className="">
                      <h3 className="text-2xl text-gray-600 py-5 font-semibold">{item?.title}</h3>
                      <p className="text-gray-800 pr-5">{item?.description}</p>
                    </div>
                  </div>
                {/* </div> */}
              </div>
            );
          })}

          <div className="flex justify-center gap-2">
            {/* Back to top */}
            <div className="submit text-center mt-16 lg:mt-0">
              <button
                type="button"
                className={classNames(
                  "btn rounded-3xl min-w-40",
                  "nst-btn"
                  // "bg-blue-800 text-white",
                  // "hover:text-blue-800 hover:bg-white hover:border-blue-800",
                )}
                onClick={() => scrollToTop()}
              >
                Back on Top
              </button>
            </div>

            {/* Contact Us */}
            <div className="contact-us text-center mt-16 lg:mt-0">
              <button
                type="button"
                className={classNames(
                  "btn rounded-3xl min-w-40",
                  "nst-btn-outline"
                  // "bg-blue-800 text-white",
                  // "hover:text-blue-800 hover:bg-white hover:border-blue-800",
                )}
                onClick={() => go(ROUTE.CONTACT_US)}
              >
                Contact Us
              </button>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
