import { RESPONSE_STATUS } from "../config";
import { mockServices } from "./data/service";
// import apiService from "./api-service";

export const serviceService = () => {
  // const api = apiService();
  // const path = "/service";

  return {
    getServices: (payload: any): Promise<any> => {
      // return api.get(`${path}s`, payload);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockServices
      })
    },
    getServiceById: (id: any): Promise<any> => {
      // return api.get(`${path}/${id}`);
      return Promise.resolve({
        status: RESPONSE_STATUS.SUCCESS,
        data: mockGetServiceById(id)
      })
    }
  }
}

const mockGetServiceById = (id: number | string) => {
  const detail = mockServices.find(r => +r.id === +id) || null;
  return detail;
}