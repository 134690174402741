import { Tour, TourCountry } from "../../interface/model/tour";

// Programs
export const mockToursThailand: Tour[] = [
  {
    id: 11,
    tour_country_id: 1,
    tour_country_code: "NST_TH",
    tour_country_title: "Thailand",
    title: "Thailand Explore",
    code: "NST_TH_01",
    short_detail: `
      Explore 14 Days
      <br/>
      Across 4 Cities on 14 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 1101,
        title: "Welcome to Thailand Explore (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1102,
        title: "Welcome to Thailand Explore (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1103,
        title: "Welcome to Thailand Explore (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1104,
        title: "Welcome to Thailand Explore (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1105,
        title: "Welcome to Thailand Explore (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1106,
        title: "Welcome to Thailand Explore (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1107,
        title: "Welcome to Thailand Explore (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1108,
        title: "Welcome to Thailand Explore (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1109,
        title: "Welcome to Thailand Explore (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1110,
        title: "Welcome to Thailand Explore (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1111,
        title: "Welcome to Thailand Explore (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 11011,
        title: "Highlight - Thailand Explore 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 11012,
        title: "Highlight - Thailand Explore 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 11013,
        title: "Highlight - Thailand Explore 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: true,
    note: "Choose your group size and start and end location",
  },
  {
    id: 12,
    tour_country_id: 1,
    tour_country_code: "NST_TH",
    tour_country_title: "Thailand",
    title: "Thailand Grand Tour",
    code: "NST_TH_02",
    short_detail: `
      Grand Tour 7 Days
      <br/>
      Across 2 Cities on 7 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 2",
    travel_period: {
      days: 7,
      nights: 6,
    },
    itineraries: [
      {
        id: 1201,
        title: "Welcome to Thailand Grand Tour (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1202,
        title: "Welcome to Thailand Grand Tour (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1203,
        title: "Welcome to Thailand Grand Tour (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1204,
        title: "Welcome to Thailand Grand Tour (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1205,
        title: "Welcome to Thailand Grand Tour (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1206,
        title: "Welcome to Thailand Grand Tour (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1207,
        title: "Welcome to Thailand Grand Tour (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 12011,
        title: "Highlight - Thailand Grand Tour 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 12012,
        title: "Highlight - Thailand Grand Tour 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 12013,
        title: "Highlight - Thailand Grand Tour 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 2,
    countries: 1,
    prices: {
      was: 0,
      from: 19000,
    },
    rating: 4,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 13,
    tour_country_id: 1,
    tour_country_code: "NST_TH",
    tour_country_title: "Thailand",
    title: "Thailand Adventure",
    code: "NST_TH_03",
    short_detail: `
      Adventure 13 Days
      <br/>
      Across 6 Cities on 13 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 3",
    travel_period: {
      days: 13,
      nights: 12,
    },
    itineraries: [
      {
        id: 1301,
        title: "Welcome to Thailand Adventure (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1302,
        title: "Welcome to Thailand Adventure (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1303,
        title: "Welcome to Thailand Adventure (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1304,
        title: "Welcome to Thailand Adventure (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1305,
        title: "Welcome to Thailand Adventure (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1306,
        title: "Welcome to Thailand Adventure (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1307,
        title: "Welcome to Thailand Adventure (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1308,
        title: "Welcome to Thailand Adventure (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1309,
        title: "Welcome to Thailand Adventure (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1310,
        title: "Welcome to Thailand Adventure (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1311,
        title: "Welcome to Thailand Adventure (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1312,
        title: "Welcome to Thailand Adventure (Day 12)",
        day: "12",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1313,
        title: "Welcome to Thailand Adventure (Day 13)",
        day: "13",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 13011,
        title: "Highlight - Thailand Adventure 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 13012,
        title: "Highlight - Thailand Adventure 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 13013,
        title: "Highlight - Thailand Adventure 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 6,
    countries: 3,
    prices: {
      was: 0,
      from: 49000,
    },
    rating: 4.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 14,
    tour_country_id: 1,
    tour_country_code: "NST_TH",
    tour_country_title: "Thailand",
    title: "Thailand Combination",
    code: "NST_TH_04",
    short_detail: `
      Combination 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 1401,
        title: "Welcome to Thailand Combination (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1402,
        title: "Welcome to Thailand Combination (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1403,
        title: "Welcome to Thailand Combination (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1404,
        title: "Welcome to Thailand Combination (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1405,
        title: "Welcome to Thailand Combination (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1406,
        title: "Welcome to Thailand Combination (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1407,
        title: "Welcome to Thailand Combination (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1408,
        title: "Welcome to Thailand Combination (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1409,
        title: "Welcome to Thailand Combination (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1410,
        title: "Welcome to Thailand Combination (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 1411,
        title: "Welcome to Thailand Combination (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 14011,
        title: "Highlight - Thailand Combination 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 14012,
        title: "Highlight - Thailand Combination 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 14013,
        title: "Highlight - Thailand Combination 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
];

export const mockToursVietnam: Tour[] = [
  {
    id: 21,
    tour_country_id: 2,
    tour_country_code: "NST_VN",
    tour_country_title: "Vietnam",
    title: "Highlight of Hanoi Vietnam",
    code: "NST_VN_01",
    short_detail: `
      Combination 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 2101,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2102,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2103,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2104,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2105,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2106,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2107,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2108,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2109,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2110,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2111,
        title: "Welcome to Highlight of Hanoi Vietnam (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 21011,
        title: "Highlight - Highlight of Hanoi Vietnam 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 21012,
        title: "Highlight - Highlight of Hanoi Vietnam 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 21013,
        title: "Highlight - Highlight of Hanoi Vietnam 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: true,
    note: "Choose your group size and start and end location",
  },
  {
    id: 22,
    tour_country_id: 2,
    tour_country_code: "NST_VN",
    tour_country_title: "Vietnam",
    title: "Highlight of Ho Chi Minh Vietnam",
    code: "NST_VN_02",
    short_detail: `
      Combination 7 Days
      <br/>
      Across 2 Cities on 7 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 2",
    travel_period: {
      days: 7,
      nights: 6,
    },
    itineraries: [
      {
        id: 2201,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2202,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2203,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2204,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2205,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2206,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2207,
        title: "Welcome to Highlight of Ho Chi Minh Vietnam (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 22011,
        title: "Highlight - Highlight of Ho Chi Minh Vietnam 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 22012,
        title: "Highlight - Highlight of Ho Chi Minh Vietnam 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 22013,
        title: "Highlight - Highlight of Ho Chi Minh Vietnam 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 2,
    countries: 1,
    prices: {
      was: 0,
      from: 19000,
    },
    rating: 4,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 23,
    tour_country_id: 2,
    tour_country_code: "NST_VN",
    tour_country_title: "Vietnam",
    title: "Highlight of Hue & Hoi An Vietnam",
    code: "NST_VN_03",
    short_detail: `
      Combination 13 Days
      <br/>
      Across 6 Cities on 13 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 3",
    travel_period: {
      days: 13,
      nights: 12,
    },
    itineraries: [
      {
        id: 2301,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2302,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2303,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2304,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2305,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2306,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2307,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2308,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2309,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2310,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2311,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2312,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 12)",
        day: "12",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2313,
        title: "Welcome to Highlight of Hue & Hoi An Vietnam (Day 13)",
        day: "13",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 23011,
        title: "Highlight - Highlight of Hue & Hoi An Vietnam 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 23012,
        title: "Highlight - Highlight of Hue & Hoi An Vietnam 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 23013,
        title: "Highlight - Highlight of Hue & Hoi An Vietnam 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 6,
    countries: 3,
    prices: {
      was: 0,
      from: 49000,
    },
    rating: 4.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 24,
    tour_country_id: 2,
    tour_country_code: "NST_VN",
    tour_country_title: "Vietnam",
    title: "Highlight of Vietnam",
    code: "NST_VN_04",
    short_detail: `
      Combination 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 2401,
        title: "Welcome to Highlight of Vietnam (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2402,
        title: "Welcome to Highlight of Vietnam (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2403,
        title: "Welcome to Highlight of Vietnam (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2404,
        title: "Welcome to Highlight of Vietnam (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2405,
        title: "Welcome to Highlight of Vietnam (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2406,
        title: "Welcome to Highlight of Vietnam (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2407,
        title: "Welcome to Highlight of Vietnam (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2408,
        title: "Welcome to Highlight of Vietnam (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2409,
        title: "Welcome to Highlight of Vietnam (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2410,
        title: "Welcome to Highlight of Vietnam (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 2411,
        title: "Welcome to Highlight of Vietnam (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 24011,
        title: "Highlight - Highlight of Vietnam 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 24012,
        title: "Highlight - Highlight of Vietnam 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 24013,
        title: "Highlight - Highlight of Vietnam 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
];

export const mockToursCambodia: Tour[] = [
  {
    id: 31,
    tour_country_id: 3,
    tour_country_code: "NST_KH",
    tour_country_title: "Cambodia",
    title: "Discover Angkor Cambodia",
    code: "NST_KH_01",
    short_detail: `
      Discover Angkor 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 3101,
        title: "Welcome to Discover Angkor Cambodia (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3102,
        title: "Welcome to Discover Angkor Cambodia (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3103,
        title: "Welcome to Discover Angkor Cambodia (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3104,
        title: "Welcome to Discover Angkor Cambodia (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3105,
        title: "Welcome to Discover Angkor Cambodia (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3106,
        title: "Welcome to Discover Angkor Cambodia (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3107,
        title: "Welcome to Discover Angkor Cambodia (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3108,
        title: "Welcome to Discover Angkor Cambodia (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3109,
        title: "Welcome to Discover Angkor Cambodia (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3110,
        title: "Welcome to Discover Angkor Cambodia (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3111,
        title: "Welcome to Discover Angkor Cambodia (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 31011,
        title: "Highlight - Discover Angkor Cambodia 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 31012,
        title: "Highlight - Discover Angkor Cambodia 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 31013,
        title: "Highlight - Discover Angkor Cambodia 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: true,
    note: "Choose your group size and start and end location",
  },
  {
    id: 32,
    tour_country_id: 3,
    tour_country_code: "NST_KH",
    tour_country_title: "Cambodia",
    title: "Highlight of Phnom Penh Cambodia",
    code: "NST_KH_02",
    short_detail: `
      Highlight of Phnom Penh 7 Days
      <br/>
      Across 2 Cities on 7 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 2",
    travel_period: {
      days: 7,
      nights: 6,
    },
    itineraries: [
      {
        id: 3201,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3202,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3203,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3204,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3205,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3206,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3207,
        title: "Welcome to Highlight of Phnom Penh Cambodia (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 32011,
        title: "Highlight - Highlight of Phnom Penh Cambodia 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 32012,
        title: "Highlight - Highlight of Phnom Penh Cambodia 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 32013,
        title: "Highlight - Highlight of Phnom Penh Cambodia 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 2,
    countries: 1,
    prices: {
      was: 0,
      from: 19000,
    },
    rating: 4,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 33,
    tour_country_id: 3,
    tour_country_code: "NST_KH",
    tour_country_title: "Cambodia",
    title: "Highlight of Seam Reap Cambodia",
    code: "NST_KH_03",
    short_detail: `
      Highlight of Seam Reap 13 Days
      <br/>
      Across 6 Cities on 13 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 3",
    travel_period: {
      days: 13,
      nights: 12,
    },
    itineraries: [
      {
        id: 3301,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3302,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3303,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3304,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3305,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3306,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3307,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3308,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3309,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3310,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3311,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3312,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 12)",
        day: "12",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 3313,
        title: "Welcome to Highlight of Seam Reap Cambodia (Day 13)",
        day: "13",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 33011,
        title: "Highlight - Highlight of Seam Reap Cambodia 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 33012,
        title: "Highlight - Highlight of Seam Reap Cambodia 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 33013,
        title: "Highlight - Highlight of Seam Reap Cambodia 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 6,
    countries: 3,
    prices: {
      was: 0,
      from: 49000,
    },
    rating: 4.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
];

export const mockToursLoas: Tour[] = [
  {
    id: 41,
    tour_country_id: 4,
    tour_country_code: "NST_LA",
    tour_country_title: "Loas",
    title: "Vientiane Stopover",
    code: "NST_LA_01",
    short_detail: `
      Vientiane Stopover 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 4101,
        title: "Welcome to Vientiane Stopover (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4102,
        title: "Welcome to Vientiane Stopover (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4103,
        title: "Welcome to Vientiane Stopover (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4104,
        title: "Welcome to Vientiane Stopover (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4105,
        title: "Welcome to Vientiane Stopover (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4106,
        title: "Welcome to Vientiane Stopover (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4107,
        title: "Welcome to Vientiane Stopover (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4108,
        title: "Welcome to Vientiane Stopover (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4109,
        title: "Welcome to Vientiane Stopover (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4110,
        title: "Welcome to Vientiane Stopover (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4111,
        title: "Welcome to Vientiane Stopover (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 41011,
        title: "Highlight - Vientiane Stopover 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 41012,
        title: "Highlight - Vientiane Stopover 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 41013,
        title: "Highlight - Vientiane Stopover 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: true,
    note: "Choose your group size and start and end location",
  },
  {
    id: 42,
    tour_country_id: 4,
    tour_country_code: "NST_LA",
    tour_country_title: "Loas",
    title: "Treasure of Laos",
    code: "NST_LA_02",
    short_detail: `
      Treasure of Laos 7 Days
      <br/>
      Across 2 Cities on 7 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 2",
    travel_period: {
      days: 7,
      nights: 6,
    },
    itineraries: [
      {
        id: 4201,
        title: "Welcome to Treasure of Laos (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4202,
        title: "Welcome to Treasure of Laos (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4203,
        title: "Welcome to Treasure of Laos (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4204,
        title: "Welcome to Treasure of Laos (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4205,
        title: "Welcome to Treasure of Laos (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4206,
        title: "Welcome to Treasure of Laos (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4207,
        title: "Welcome to Treasure of Laos (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 42011,
        title: "Highlight - Treasure of Laos 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 42012,
        title: "Highlight - Treasure of Laos 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 42013,
        title: "Highlight - Treasure of Laos 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 2,
    countries: 1,
    prices: {
      was: 0,
      from: 19000,
    },
    rating: 4,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 43,
    tour_country_id: 4,
    tour_country_code: "NST_LA",
    tour_country_title: "Loas",
    title: "Discover Loas",
    code: "NST_LA_03",
    short_detail: `
      Discover Loas 13 Days
      <br/>
      Across 6 Cities on 13 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 3",
    travel_period: {
      days: 13,
      nights: 12,
    },
    itineraries: [
      {
        id: 4301,
        title: "Welcome to Discover Loas (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4302,
        title: "Welcome to Discover Loas (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4303,
        title: "Welcome to Discover Loas (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4304,
        title: "Welcome to Discover Loas (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4305,
        title: "Welcome to Discover Loas (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4306,
        title: "Welcome to Discover Loas (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4307,
        title: "Welcome to Discover Loas (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4308,
        title: "Welcome to Discover Loas (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4309,
        title: "Welcome to Discover Loas (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4310,
        title: "Welcome to Discover Loas (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4311,
        title: "Welcome to Discover Loas (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4312,
        title: "Welcome to Discover Loas (Day 12)",
        day: "12",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4313,
        title: "Welcome to Discover Loas (Day 13)",
        day: "13",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 43011,
        title: "Highlight - Discover Loas 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 43012,
        title: "Highlight - Discover Loas 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 43013,
        title: "Highlight - Discover Loas 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 6,
    countries: 3,
    prices: {
      was: 0,
      from: 49000,
    },
    rating: 4.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 44,
    tour_country_id: 4,
    tour_country_code: "NST_LA",
    tour_country_title: "Loas",
    title: "Wonder of Luang Prabang",
    code: "NST_LA_04",
    short_detail: `
      Wonder of Luang Prabang 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 4401,
        title: "Welcome to Wonder of Luang Prabang (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4402,
        title: "Welcome to Wonder of Luang Prabang (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4403,
        title: "Welcome to Wonder of Luang Prabang (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4404,
        title: "Welcome to Wonder of Luang Prabang (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4405,
        title: "Welcome to Wonder of Luang Prabang (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4406,
        title: "Welcome to Wonder of Luang Prabang (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4407,
        title: "Welcome to Wonder of Luang Prabang (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4408,
        title: "Welcome to Wonder of Luang Prabang (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4409,
        title: "Welcome to Wonder of Luang Prabang (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4410,
        title: "Welcome to Wonder of Luang Prabang (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 4411,
        title: "Welcome to Wonder of Luang Prabang (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 44011,
        title: "Highlight - Wonder of Luang Prabang 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 44012,
        title: "Highlight - Wonder of Luang Prabang 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 44013,
        title: "Highlight - Wonder of Luang Prabang 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
];

export const mockToursMyanmar: Tour[] = [
  {
    id: 51,
    tour_country_id: 5,
    tour_country_code: "NST_MM",
    tour_country_title: "Myanmar",
    title: "Treasure of Myanmar",
    code: "NST_MM_01",
    short_detail: `
      Treasure of Myanmar 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 5101,
        title: "Welcome to Treasure of Myanmar (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5102,
        title: "Welcome to Treasure of Myanmar (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5103,
        title: "Welcome to Treasure of Myanmar (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5104,
        title: "Welcome to Treasure of Myanmar (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5105,
        title: "Welcome to Treasure of Myanmar (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5106,
        title: "Welcome to Treasure of Myanmar (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5107,
        title: "Welcome to Treasure of Myanmar (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5108,
        title: "Welcome to Treasure of Myanmar (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5109,
        title: "Welcome to Treasure of Myanmar (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5110,
        title: "Welcome to Treasure of Myanmar (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5111,
        title: "Welcome to Treasure of Myanmar (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 51011,
        title: "Highlight - Treasure of Myanmar 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 51012,
        title: "Highlight - Treasure of Myanmar 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 51013,
        title: "Highlight - Treasure of Myanmar 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: true,
    note: "Choose your group size and start and end location",
  },
  {
    id: 52,
    tour_country_id: 5,
    tour_country_code: "NST_MM",
    tour_country_title: "Myanmar",
    title: "Yangon Stopover",
    code: "NST_MM_02",
    short_detail: `
      Yangon Stopover 7 Days
      <br/>
      Across 2 Cities on 7 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 2",
    travel_period: {
      days: 7,
      nights: 6,
    },
    itineraries: [
      {
        id: 5201,
        title: "Welcome to Yangon Stopover (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5202,
        title: "Welcome to Yangon Stopover (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5203,
        title: "Welcome to Yangon Stopover (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5204,
        title: "Welcome to Yangon Stopover (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5205,
        title: "Welcome to Yangon Stopover (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5206,
        title: "Welcome to Yangon Stopover (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5207,
        title: "Welcome to Yangon Stopover (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 52011,
        title: "Highlight - Yangon Stopover 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 52012,
        title: "Highlight - Yangon Stopover 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 52013,
        title: "Highlight - Yangon Stopover 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 2,
    countries: 1,
    prices: {
      was: 0,
      from: 19000,
    },
    rating: 4,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 53,
    tour_country_id: 5,
    tour_country_code: "NST_MM",
    tour_country_title: "Myanmar",
    title: "Discover Myanmar",
    code: "NST_MM_03",
    short_detail: `
      Discover Myanmar 13 Days
      <br/>
      Across 6 Cities on 13 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 3",
    travel_period: {
      days: 13,
      nights: 12,
    },
    itineraries: [
      {
        id: 5301,
        title: "Welcome to Discover Myanmar (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5302,
        title: "Welcome to Discover Myanmar (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5303,
        title: "Welcome to Discover Myanmar (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5304,
        title: "Welcome to Discover Myanmar (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5305,
        title: "Welcome to Discover Myanmar (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5306,
        title: "Welcome to Discover Myanmar (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5307,
        title: "Welcome to Discover Myanmar (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5308,
        title: "Welcome to Discover Myanmar (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5309,
        title: "Welcome to Discover Myanmar (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5310,
        title: "Welcome to Discover Myanmar (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5311,
        title: "Welcome to Discover Myanmar (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5312,
        title: "Welcome to Discover Myanmar (Day 12)",
        day: "12",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 5313,
        title: "Welcome to Discover Myanmar (Day 13)",
        day: "13",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 53011,
        title: "Highlight - Discover Myanmar 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 53012,
        title: "Highlight - Discover Myanmar 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 53013,
        title: "Highlight - Discover Myanmar 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 6,
    countries: 3,
    prices: {
      was: 0,
      from: 49000,
    },
    rating: 4.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
];

export const mockToursIndoChina: Tour[] = [
  {
    id: 61,
    tour_country_id: 6,
    tour_country_code: "NST_IN",
    tour_country_title: "Indochina",
    title: "Discover Indochina",
    code: "NST_IN_01",
    short_detail: `
      Discover Indochina 11 Days
      <br/>
      Across 4 Cities on 11 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 2000,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 1",
    travel_period: {
      days: 11,
      nights: 10,
    },
    itineraries: [
      {
        id: 6101,
        title: "Welcome to Discover Indochina (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6102,
        title: "Welcome to Discover Indochina (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6103,
        title: "Welcome to Discover Indochina (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6104,
        title: "Welcome to Discover Indochina (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6105,
        title: "Welcome to Discover Indochina (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6106,
        title: "Welcome to Discover Indochina (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6107,
        title: "Welcome to Discover Indochina (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6108,
        title: "Welcome to Discover Indochina (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6109,
        title: "Welcome to Discover Indochina (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6110,
        title: "Welcome to Discover Indochina (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6111,
        title: "Welcome to Discover Indochina (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 61011,
        title: "Highlight - Discover Indochina 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 61012,
        title: "Highlight - Discover Indochina 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 61013,
        title: "Highlight - Discover Indochina 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 4,
    countries: 2,
    prices: {
      was: 31000,
      from: 29000,
    },
    rating: 3.5,
    review_count: 0,
    is_recommended: true,
    note: "Choose your group size and start and end location",
  },
  {
    id: 62,
    tour_country_id: 6,
    tour_country_code: "NST_IN",
    tour_country_title: "Indochina",
    title: "Indochina Remarkable",
    code: "NST_IN_02",
    short_detail: `
      Indochina Remarkable 7 Days
      <br/>
      Across 2 Cities on 7 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 2",
    travel_period: {
      days: 7,
      nights: 6,
    },
    itineraries: [
      {
        id: 6201,
        title: "Welcome to Indochina Remarkable (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6202,
        title: "Welcome to Indochina Remarkable (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6203,
        title: "Welcome to Indochina Remarkable (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6204,
        title: "Welcome to Indochina Remarkable (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6205,
        title: "Welcome to Indochina Remarkable (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6206,
        title: "Welcome to Indochina Remarkable (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6207,
        title: "Welcome to Indochina Remarkable (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 62011,
        title: "Highlight - Indochina Remarkable 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 62012,
        title: "Highlight - Indochina Remarkable 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 62013,
        title: "Highlight - Indochina Remarkable 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 2,
    countries: 1,
    prices: {
      was: 0,
      from: 19000,
    },
    rating: 4,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
  {
    id: 63,
    tour_country_id: 6,
    tour_country_code: "NST_IN",
    tour_country_title: "Indochina",
    title: "Senses of Indochina",
    code: "NST_IN_03",
    short_detail: `
      Senses of Indochina 13 Days
      <br/>
      Across 6 Cities on 13 Days
    `,
    detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
    discount: 0,
    image: "/asset/images/mock/card.png",
    image_cover: "/asset/images/mock/card.png",
    image_cover_h: "/asset/images/mock/card-h.png",
    image_highlight: "/asset/images/mock/tour-highlight.png",
    travel_type: "Country Roads 3",
    travel_period: {
      days: 13,
      nights: 12,
    },
    itineraries: [
      {
        id: 6301,
        title: "Welcome to Discover Indochina (Day 1)",
        day: "1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6302,
        title: "Welcome to Discover Indochina (Day 2)",
        day: "2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6303,
        title: "Welcome to Discover Indochina (Day 3)",
        day: "3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6304,
        title: "Welcome to Discover Indochina (Day 4)",
        day: "4",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6305,
        title: "Welcome to Discover Indochina (Day 5)",
        day: "5",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6306,
        title: "Welcome to Discover Indochina (Day 6)",
        day: "6",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6307,
        title: "Welcome to Discover Indochina (Day 7)",
        day: "7",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6308,
        title: "Welcome to Discover Indochina (Day 8)",
        day: "8",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6309,
        title: "Welcome to Discover Indochina (Day 9)",
        day: "9",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6310,
        title: "Welcome to Discover Indochina (Day 10)",
        day: "10",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6311,
        title: "Welcome to Discover Indochina (Day 11)",
        day: "11",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6312,
        title: "Welcome to Discover Indochina (Day 12)",
        day: "12",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 6313,
        title: "Welcome to Discover Indochina (Day 13)",
        day: "13",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
    ],
    highlights: [
      {
        id: 63011,
        title: "Highlight - Senses of Indochina 1",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 63012,
        title: "Highlight - Senses of Indochina 2",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      },
      {
        id: 63013,
        title: "Highlight - Senses of Indochina 3",
        detail: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, ad illo asperiores id non libero est suscipit accusamus laboriosam totam deserunt rerum blanditiis necessitatibus voluptas animi dignissimos nemo maiores fugiat.",
        image: "/asset/images/mock/image.png",
      }
    ],
    locations: 6,
    countries: 3,
    prices: {
      was: 0,
      from: 49000,
    },
    rating: 4.5,
    review_count: 0,
    is_recommended: false,
    note: "Choose your group size and start and end location",
  },
];

// Group by countries
export const mockToursCountries: TourCountry[] = [
  {
    id: 1,
    title: "Thailand",
    code: "NST_TH",
    description:
      "Discover Thailand, the Land of Smiles, where ancient temples, stunning beaches, and vibrant cities come together. From the bustling streets of Bangkok to the tranquil mountains of Chiang Mai, experience a journey filled with culture and natural beauty.",
    image: "",
    tours: mockToursThailand,
  },
  {
    id: 2,
    title: "Vietnam",
    code: "NST_VN",
    description:
      "Vietnam invites you on a journey through its picturesque landscapes, from the emerald waters of Halong Bay to the terraced rice fields of Sapa. Dive into the bustling energy of cities like Hanoi and Ho Chi Minh City, and savor the flavors of its world-renowned cuisine.",
    image: "",
    tours: mockToursVietnam,
  },
  {
    id: 3,
    title: "Cambodia",
    code: "NST_KH",
    description:
      "Step into Cambodia, a land of ancient wonders and rich heritage. Home to the majestic temples of Angkor Wat and vibrant local traditions, this country offers a unique blend of history, culture, and warm hospitality that leaves a lasting impression.",
    image: "",
    tours: mockToursCambodia,
  },
  {
    id: 4,
    title: "Laos",
    code: "NST_LA",
    description:
      "Laos is a hidden gem in Southeast Asia, known for its laid-back atmosphere and stunning natural landscapes. From the peaceful streets of Luang Prabang to the mystical beauty of the Mekong River, it offers a calm and peaceful escape filled with culture and grace.",
    image: "",
    tours: mockToursLoas,
  },
  {
    id: 5,
    title: "Myanmar",
    code: "NST_MM",
    description:
      "Myanmar, the Golden Land, is a country where time seems to stand still. Discover the timeless beauty of Bagan's temple-studded plains, the serene waters of Inle Lake, and the rich traditions that make this country a truly unique experience.",
    image: "",
    tours: mockToursMyanmar,
  },
  {
    id: 6,
    title: "Indochina",
    code: "NST_IN",
    description:
      "Indochina is where tradition meets elegance in Southeast Asia. This region, spanning Thailand, Vietnam, Cambodia, Laos, and Myanmar, is a fusion of ancient history, stunning landscapes, and diverse cultures. Discover hidden temples, vibrant traditions, and the timeless charm of bustling cities. Let’s uncover these wonders together!",
    image: "",
    tours: mockToursIndoChina,
  },
];
