export const mockServices: any[] = [
  {
    id: 1,
    title: "Tailor-Made",
    description: "Northern Smile Travel, we specialize in creating tailor-made trips that match your interests, passions, and travel style. Imagine a trip where every experience is chosen to suit your tastes, whether it's exploring hidden gems, diving into local cultures, or enjoying off-the-beaten-path adventures. Let us craft your perfect journey, turning your travel dreams into reality.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 11,
        title: "Tour Style",
        description: "Let us know your preferred tour style, and we'll tailor the experience to suit your travel plans perfectly.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 12,
        title: "Itinerary",
        description: "If you have specific travel plans or sightseeing preferences, please share them with us.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 13,
        title: "Accommodation",
        description: "Share your preferred type of accommodation and budget to ensure a comfortable stay during your trip.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
  {
    id: 2,
    title: "Day Trips",
    description: "Discover the beauty and culture of each destination with our specially crafted day trips! We offer both a small group experience and the exclusivity of a private tour. Our day trips are designed to immerse you in local culture, from visiting vibrant markets to exploring historic sites. With knowledgeable guides leading the way, you’ll gain insights that enrich your experience.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 21,
        title: "Recommended Trips",
        description: "Our recommended trips are designed for first-time visitors to each destination. Experience the highlights and hidden treasures that showcase the unique charm and culture of the area. Let us guide you on your journey!",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 22,
        title: "Experienced Trips",
        description: "Our experienced trips are perfect for those who have explored the basics and are ready to delve deeper. Discover lesser-known attractions, engage with local communities, and immerse yourself in the rich culture and history of each destination. Join us for a journey that goes beyond the ordinary!",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 23,
        title: "Special Trips",
        description: "Design a day trip that reflects your interests—whether it’s local cuisine, cultural experiences, or hidden gems. We’ll help you create a personalized itinerary that’s all about you.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
  {
    id: 3,
    title: "Group",
    description: "If you're planning a corporate incentive trip, special event, or a getaway with friends and family, we provide customized group travel solutions tailored to your needs. From transportation and accommodation to guided tours and exclusive activities, we take care of every detail to ensure a seamless experience. Our team designs itineraries that cater to any group size and interest, creating a journey that's uniquely yours.",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 31,
        title: "Incentive Group Tours",
        description: "We create customized itineraries combining team-building, cultural experiences, and unique adventures. From logistics to special events, we handle every detail to create a journey that boosts team spirit.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 32,
        title: "Student Group Tours",
        description: "We offer a unique opportunity for young minds to learn beyond the classroom with our educational, engaging, and fun student tours. Our tailored itineraries blend learning experiences, cultural exploration, and adventure to suit the specific age group and purpose of the trip.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 33,
        title: "Senior Group Tours",
        description: "We thoughtfully design our tours for retirees and those seeking a comfortable, accessible travel experience. With easy-paced itineraries, minimal walking, and wheelchair-accessible options, we ensure every guest enjoys a hassle-free journey.",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 34,
        title: "Special Group Tours",
        description: "Whether you seek sports, adventure, or specialized experiences, we can craft tailored itineraries that perfectly align with your group's interests.",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
  {
    id: 4,
    title: "Event",
    description: "A variety of events—meetings, conferences, and special occasions—let us help you create",
    image: "/asset/images/mock/cover-service.png",
    details: [
      {
        id: 41,
        title: "Meeting",
        description: "",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 42,
        title: "Conference",
        description: "",
        image: "/asset/images/mock/service.png",
      },
      {
        id: 43,
        title: "Event",
        description: "",
        image: "/asset/images/mock/service.png",
      }
    ]
  },
];